<template>
  <module
    ref="module"
    id="followers"
    titleIcon="fa fa-users"
    :title="$t('followerstxt')"
    :filters.sync="filters"
    :visibilityFields.sync="tableColumns"
    :showVisibilityButton="true"
    apiUrl="ACCAPIURL_V4"
    :url="`/${userIdUrl}/followers`"
    @on-select="passCurrentRow"
  >
    <template slot="table-columns">
      <el-table-column
        :label="$t('optionstxt')"
        align="center"
        width="130px"
      >
        <template slot-scope="{row}">
          <p-button type="primary" link @click="showModalPrice">
            <i class="fa fa-money" style="font-size: x-large"></i>
          </p-button>
          <el-tooltip :content="$t('parameters_txt')" placement="top" >
            <p-button type="warning" link @click="seeParams(row.id, row.strat_id)">
              <i class="fa fa-eye" style="font-size: x-large;"></i>
            </p-button>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.account.prop"
        v-if="tableColumns.account.isVisible"
        :label="tableColumns.account.label"
        sortable
        width="130px"
      ></el-table-column>
      <el-table-column
        :prop="tableColumns.full_name.prop"
        v-if="tableColumns.full_name.isVisible"
        :label="tableColumns.full_name.label"
        sortable
        width="310px"
      ></el-table-column>
      <el-table-column
        :prop="tableColumns.account_type.prop"
        v-if="tableColumns.account_type.isVisible"
        :label="tableColumns.account_type.label"
        align="center"
        width="160px"
      >
        <template slot-scope="{row}">
          <span v-if="row.demo">&nbsp;</span>
          <badge type="info" v-if="row.demo">demo</badge>
          <span v-if="!row.demo && row.sponsored">&nbsp;</span>
          <el-tooltip :content="sponsoredtt" :open-delay="100" placement="top" popper-class="adjust-width">
            <badge type="warning" v-if="!row.demo && row.sponsored">
              {{ $t('sponsoredtxt') }}
            </badge>
          </el-tooltip>
          <badge type="success" v-if="!row.demo && !row.sponsored">live</badge>
        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.strat_name.prop"
        v-if="tableColumns.strat_name.isVisible"
        :label="tableColumns.strat_name.label"
        sortable
        width="200px"
      ></el-table-column>
      <el-table-column
        class="text-right"
        :prop="tableColumns.balance.prop"
        v-if="tableColumns.balance.isVisible"
        :label="tableColumns.balance.label"
        sortable
        align="right"
        width="140px"
      >
        <template slot-scope="{row}">
          <span>
            {{ row.currency }}
          </span>
          <span class="text-success">
            {{ parseFloat(row.balance).toFixed(2) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        class="text-right"
        :prop="tableColumns.equity.prop"
        v-if="tableColumns.equity.isVisible"
        :label="tableColumns.equity.label"
        sortable
        align="right"
        width="140px"
      >
        <template slot-scope="{row}">
          <span>
            {{ row.currency }}
          </span>
          <span class="text-success">
            {{ parseFloat(row.equity).toFixed(2) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        class="text-right"
        :prop="tableColumns.leverage.prop"
        v-if="tableColumns.leverage.isVisible"
        :label="tableColumns.leverage.label"
        sortable
        align="right"
        width="140px"
      ></el-table-column>
      <el-table-column
        class="text-right"
        :prop="tableColumns.margin_level.prop"
        v-if="tableColumns.margin_level.isVisible"
        :label="tableColumns.margin_level.label"
        sortable
        align="right"
        width="150px"
      >
        <template slot-scope="scope">
          <span
            :class="defineMarginLevelTextColor(scope)"
            v-if="parseFloat(scope.row.equity) && parseFloat(scope.row.margin)"
          >
            {{ calcMarginLevel(scope) }}%
          </span>
        </template>
      </el-table-column>
      <el-table-column
        class="text-right"
        :prop="tableColumns.profit.prop"
        v-if="tableColumns.profit.isVisible"
        :label="tableColumns.profit.label"
        sortable
        align="right"
        width="150px"
      >
        <template slot-scope="{row}">
          <span>
            {{ row.currency }}
          </span>
          <span
            class="text-success"
            v-if="row.profit && row.profit > 0"
          >
            {{ parseFloat(row.profit).toFixed(2) }}
          </span>
          <span
            class="text-danger"
            v-if="row.profit && row.profit <= 0"
          >
            {{ parseFloat(row.profit).toFixed(2) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.start_date.prop"
        v-if="tableColumns.start_date.isVisible"
        :label="tableColumns.start_date.label"
        sortable
        align="center"
        width="130px"
      ></el-table-column>
      <el-table-column
        class="text-right"
        :prop="tableColumns.total_profit.prop"
        v-if="tableColumns.total_profit.isVisible"
        :label="tableColumns.total_profit.label"
        sortable
        align="right"
        width="160px"
      >
        <template slot-scope="{row}">
          <span>
            {{ row.currency }}
            <span
              :class="defineTextColor(row.total_profit)"
            >
              {{ parseFloat(row.total_profit).toFixed(2) }}
            </span>
          </span>

        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.unbilled_profit.prop"
        v-if="tableColumns.unbilled_profit.isVisible"
        :label="tableColumns.unbilled_profit.label"
        sortable
        align="right"
        width="170px"
      >
        <template slot-scope="{row}">
          <span v-if="row.currency == 'USD' ">
            {{ $t('under_fix') }}
          </span>
          <span v-else>
            {{ row.currency }}
            <span
              :class="defineTextColor(row.unbilled_profit)"
            >
              {{ row.unbilled_profit }}
            </span>
          </span>

        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.price.prop"
        v-if="tableColumns.price.isVisible"
        :label="tableColumns.price.label"
        sortable
        align="right"
        width="220px"
      >
        <template slot-scope="{row}">
          <span v-if="row.price > 0"> {{ row.price * 100 }}% </span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.allocation_method.prop"
        v-if="tableColumns.allocation_method.isVisible"
        :label="tableColumns.allocation_method.label"
        sortable
        width="180px"
      >
        <template slot-scope="{row}">
          <span v-if="row.allocation_method === 'eqRatio'">
            {{ $t("helper_eqratio") }} = {{ row.eqRatio }}
          </span>
          <span v-if="row.allocation_method === 'balRatio'">
            {{ $t("helper_balratio") }} = {{ row.balRatio }}
          </span>
          <span v-if="row.allocation_method === 'lotMultiplier'">
            {{ $t("helper_lotmultiplier") }} = {{ row.lotMultiplier }}
          </span>
          <span v-if="row.allocation_method === 'lotMoneyRatio'">
            {{
              $t("allocationmethodlotmoneyxy", [
                row.lotMoneyRatioX,
                row.lotMoneyRatioY,
              ])
            }}
          </span>
          <span v-if="row.allocation_method === 'fixedLots'">
            {{ $t("helper_fixedlots") }} = {{ row.fixedLots }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        :prop="tableColumns.stopout_level.prop"
        v-if="tableColumns.stopout_level.isVisible"
        :label="tableColumns.stopout_level.label"
        sortable
        align="right"
        width="160px">
        <template slot-scope="{row}">
          <span>
            {{ row.stopout_level }}%
          </span>
        </template>
      </el-table-column>
    </template>
    <template slot="filter-field">
      <div class="form-group has-label">
        <label>{{$t('accountType')}}</label>
        <el-select v-model="filters.account_type">
          <el-option
            class="select-default"
            v-for="p in accountType"
            :key="p.prop" :label="p.label" :value="p.prop"
          >
          </el-option>
        </el-select>
      </div>
      <fg-input
        v-model="filters.account"
        :label="$t('accountNumber')"
      ></fg-input>
      <fg-input
        v-model="filters.strat_name"
        :label="$t('namestrategy')"
      ></fg-input>
    </template>
    <template slot="visibility-field"></template>
    <template slot="global">
      <followers-update-price
        :follower="selectedRow"
        :showModal="isModalPrice"
        @on-close-modal-price="closeModalPrice"
        @success-up-price-handler="refreshList"
      />
      <parameter-preview></parameter-preview>
    </template>
  </module>
</template>

<script>
import FollowersUpdatePrice from "@/components/Dashboard/Views/Dashboard/Affiliate/FollowersUpdatePrice";
import Constants from "src/assets/constants";
import { Tooltip } from "element-ui";
import { Badge } from 'src/components/UIComponents'
import PSwitch from "src/components/UIComponents/PSwitch";
import {mapActions, mapState} from "vuex";
import parameterPreview from "@/store/modules/parameter_preview";
import ParameterPreview from "@/components/Dashboard/Views/Dashboard/Affiliate/ParameterPreview.vue";

const rules = Constants.methods.getRules();
const visibility = localStorage.getItem('visibility_items');
const json = visibility ? JSON.parse(visibility) : null;

export default {
  name: "Followers",
  components: {
    ParameterPreview,
    PSwitch,
    [Tooltip.name]: Tooltip,
    FollowersUpdatePrice,
    Badge,
  },
  computed: {
    userIdUrl() {
      let userid = rules.userid;
      if (rules.isAdmin) {
        userid = this.$route.params.userid;
      }
      return userid;
    },
  },
  data() {
    let self = this;
    return {
      isModalPrice: false,
      selectedRow: {},
      filters: {},
      sponsoredtt: this.$t('sponsoredtt'),
      accountType: [
        {'prop': 'sponsored', 'label': this.$t('sponsoredtxt')},
        {'prop': 'live', 'label': 'Live'},
        {'prop': 'demo', 'label': 'Demo'},
        {'prop': 'all', 'label': this.$t('alltxt')},
      ],
      tableColumns: json || {
        account: {
          prop: 'account',
          label: self.$t('account'),
          isVisible: true,
        },
        full_name: {
          prop: 'full_name',
          label: self.$t('fullname'),
          isVisible: true,
        },
        account_type: {
          prop: 'account_type',
          label: self.$t('accountType'),
          isVisible: false,
        },
        strat_name: {
          prop: 'strat_name',
          label: self.$t('strategytxt'),
          isVisible: true,
        },
        balance: {
          prop: 'balance',
          label: self.$t('balance'),
          isVisible: true,
        },
        equity: {
          prop: 'equity',
          label: self.$t('equity'),
          isVisible: true,
        },
        leverage: {
          prop: 'leverage',
          label: self.$t('leveragetxt'),
          isVisible: false,
        },
        margin_level: {
          prop: 'margin_level',
          label: self.$t('margin_level_txt'),
          isVisible: false,
        },
        profit: {
          prop: 'profit',
          label: self.$t('openpl'),
          isVisible: false,
        },
        start_date: {
          prop: 'start_date',
          label: self.$t('startdate'),
          isVisible: true,
        },
        total_profit: {
          prop: 'total_profit',
          label: self.$t('totalprofit'),
          isVisible: true,
        },
        unbilled_profit: {
          prop: 'unbilled_profit',
          label: self.$t('unbilledprofit'),
          isVisible: false,
        },
        price: {
          prop: 'price',
          label: self.$t('price'),
          isVisible: true,
        },
        allocation_method: {
          prop: 'allocation_method',
          label: self.$t('param_allocation'),
          isVisible: true,
        },
        stopout_level: {
          prop: 'stopout_level',
          label: self.$t('stopoutlevel'),
          isVisible: false,
        },
      },
    };
  },
  methods: {
    ...mapActions('parameter_preview', ['refreshInfo', 'openParameterPreviewModal', 'closeParameterPreviewModal']),
    seeParams(acc_id, strat_id) {
      const params = {
        strat_id: strat_id,
        acc_id: acc_id,
      }

      this.$getAccountsParamsAsMasterView_v4(params).then(
        response => {
          if(!response.success) {
            return this.$toast.warning(response.msg)
          }
          this.loadParams(response)
      })

    },
    loadParams(response){
      this.refreshInfo(Object.assign({}, response.data || {}));
      this.openParameterPreviewModal()
    },
    closeModalPrice() {
      this.isModalPrice = false;
    },
    refreshList() {
      this.$refs.module.refreshList();
    },
    passCurrentRow(module) {
      this.selectedRow = module.selectedRow;
    },
    showModalPrice() {
      this.isModalPrice = true;
    },
    calcMarginLevel(scope) {
      return ((parseFloat(scope.row.equity).toFixed(2) / parseFloat(scope.row.margin).toFixed(2)) * 100).toFixed(2)
    },
    defineMarginLevelTextColor(scope) {
      if (this.calcMarginLevel(scope) < 100) {
        return 'text-danger';
      } else if (this.calcMarginLevel(scope) > 100 && this.calcMarginLevel(scope) < 500) {
        return 'text-default';
      } else if (this.calcMarginLevel(scope) > 500) {
        return 'text-success';
      }
    },
    defineTextColor(val) {
      if (val > 0) {
        return 'text-success';
      } else if (val < 0) {
        return 'text-danger';
      } else {
        return 'text-default';
      }
    },
  },

};
</script>
